export const PAGE_TITLE = "Vendors";
export const PAGE_SINGLE_TITLE = "Vendor";
export const LINK_URL = "vendors";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "text",
  },
  email: {
    type: "string",
    required: true,
    title: "Email",
    inputType: "text",
  },
  phone: {
    type: "string",
    required: true,
    title: "Phone",
    inputType: "text",
  },
  password: {
    type: "string",
    required: true,
    title: "Password",
    inputType: "password",
    hideOnView: true,
    hideOnEdit: true,
  },
  vendor_divider: {
    type: "divider",
    required: false,
    title: "Vendor Details",
    inputType: "text",
  },
};
export const initialValues = {
  name: "",
};

export const view_all_table = [{ name: "Name", value: "name" }];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
