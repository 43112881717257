export const PAGE_TITLE = 'Products';
export const PAGE_SINGLE_TITLE = 'Product';
export const LINK_URL = 'products';
export const inputFields = {
  name: {
    type: 'string',
    required: true,
    title: 'Name',
    inputType: 'text',
    slug: true,
  },
  collections: {
    type: 'related',
    required: false,
    title: 'Collections',
    inputType: 'text',
    multiple: true,
  },

  slug: {
    type: 'slug',
    required: true,
    title: 'Slug',
    inputType: 'text',
  },
  description: {
    type: 'html',
    required: true,
    title: 'Description',
    inputType: 'text',
  },
  media: {
    type: 'gallery',
    required: false,
    title: 'Media',
    inputType: 'text',
  },
  pricing_divider: {
    type: 'divider',
    title: 'Pricing',
  },
  regular_price: {
    type: 'string',
    required: true,
    title: 'Regular Price',
    inputType: 'number',
  },
  sale_price: {
    type: 'string',
    required: true,
    title: 'Sale Price',
    inputType: 'number',
  },
  tax: {
    type: 'string',
    required: false,
    title: 'Tax',
    inputType: 'number',
  },
  inventory_divider: {
    type: 'divider',
    title: 'Inventory',
  },
  sku: {
    type: 'string',
    required: false,
    title: 'Product Unique Code',
  },
  in_stock: {
    type: 'checkbox',
    required: false,
    title: 'In Stock',
  },
  stock: {
    type: 'string',
    required: false,
    title: 'Stock',
    inputType: 'number',
  },
  shipping: {
    type: 'divider',
    title: 'Shipping Details',
  },
  weight: {
    type: 'string',
    required: false,
    title: 'Weight (KG)',
    inputType: 'number',
  },

  length: {
    type: 'string',
    required: false,
    title: 'Length (CM)',
    inputType: 'number',
  },
  width: {
    type: 'string',
    required: false,
    title: 'Width (CM)',
    inputType: 'number',
  },
  height: {
    type: 'string',
    required: false,
    title: 'Height (CM)',
    inputType: 'number',
  },
  options: {
    type: 'divider',
    title: 'Options',
  },
  is_variable_product: {
    type: 'checkbox',
    required: false,
    title: 'This product has options, like size or color',
  },
};
export const initialValues = {
  name: '',
  is_variable_product: false,
};

export const view_all_table = [{ name: 'Name', value: 'name' }];

export const SIDEBAR_OPTIONS = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
];
